<template>
  <div class="box">
    <navbar title="我的信息"></navbar>
    <div class="list">
      <div class="item">
        <div class="l">个人姓名</div>
        <div class="r">
          <input
            type="text"
            v-model="form.name"
            placeholder="请输入您的个人姓名"
          />
        </div>
      </div>
      <div class="item1" @click="show = true">
        <div class="l">性别</div>
        <div class="r">
          {{ form.sex == 1 ? "男" : form.sex == 2 ? "女" : "暂无"
          }}<img src="../assets/youjt.png" alt="" />
        </div>
      </div>
      <div class="item">
        <div class="l">手机联系方式</div>
        <div class="r">
          <input
            v-model="form.phone"
            type="text"
            disabled
            placeholder="请输入手机联系方式"
          />
        </div>
      </div>
      <div class="item">
        <div class="l">微信号</div>
        <div class="r">
          <input
            v-model="form.wx"
            type="text"
            disabled
            placeholder="请输入微信号"
          />
        </div>
      </div>
      <div class="item">
        <div class="l">沪惠学ID</div>
        <div class="r">
          <input v-model="form.post" type="text" disabled />
        </div>
      </div>
      <div class="item">
        <div class="l">身份</div>
        <div class="r">
          {{
            form.type == 1
              ? "销售"
              : form.type == 2
              ? "会员"
              : form.type == 3
              ? "渠道"
              : form.type == 5
              ? "渠道"
              : "暂无"
          }}
        </div>
      </div>
    </div>
    <div class="btn" @click="btn">确认</div>
    <van-popup v-model="show" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      show: false,
      columns: ["男", "女"],
      form: {
        sex: "",
        name: "",
        phone: "",
        type: "",
        wx: "",
        is_admin: "",
        post: "",
        id: "",
      },
    };
  },
  methods: {
    ...mapActions(["getinformation", "getinformation_up"]),
    onConfirm(value, index) {
      this.show = !this.show;

      this.form.sex = value == "男" ? 1 : 2;
      console.log(this.form.sex);
    },
    onCancel() {
      this.show = !this.show;
    },
    btn() {
      console.log(this.form.sex);

      this.getinformation_up({
        id: this.userinfo.id,
        sex: this.form.sex,
        name: this.form.name,
        wx: this.form.wx,
      }).then((res) => {
        if (res.SuccessCode == 200) {
          this.$toast.success(res.Message);
        } else {
          this.$toast.fail(res.Message);
        }
      });
    },
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
    }),
  },
  mounted() {
    this.getinformation({
      member_id: this.userinfo.id,
    }).then((res) => {
      if (res.SuccessCode == 200) {
        for (const key in this.form) {
          this.form[key] = res.ResponseBody.info[key];
        }
        this.form.post = res.ResponseBody.info.id;
        console.log(this.form);
      }
    });
  },
};
</script>

<style lang='scss' scoped>
.box {
  height: 100vh;
  background-color: #f7f7f7;
}
.list {
  margin-top: 0.2rem;
  background-color: #fff;
  padding: 0 0.48rem;
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.12rem;
    background: #ffffff;
    border-bottom: 1px solid #ebeef5;
    .l {
      font-size: 0.32rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .r {
      display: flex;
      align-items: center;
      font-size: 0.32rem;
      input {
        font-size: 0.32rem;
        text-align: right;
        width: 3rem;
        height: 0.4rem;
        border: 0px none;
        &:disabled {
          background-color: #fff;
        }
      }
    }
  }
  .item1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.12rem;
    background: #ffffff;
    border-bottom: 1px solid #ebeef5;
    .l {
      font-size: 0.32rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .r {
      display: flex;
      align-items: center;
      font-size: 0.3rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #c9ced6;
      img {
        margin-left: 0.3rem;
        width: 0.3rem;
      }
    }
  }
}
.btn {
  position: fixed;
  width: 7.1rem;
  bottom: 0.63rem;
  margin-left: 0.2rem;
  height: 0.96rem;
  background: linear-gradient(90deg, #458aec 0%, #6ab4fd 100%);
  border-radius: 0.14rem;
  font-size: 0.36rem;
  line-height: 0.96rem;
  text-align: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
</style>